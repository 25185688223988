@import "../../sass/variable";
@import "../../sass/utilities";

.wrapper {
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  width: $sidenav_width;
  flex-grow: 0.4;
  &.active {
    margin-left: 0;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .sidenav {
    width: 100%;
    background-color: white;
    padding: 1.5rem 0;
    height: max-content;
    min-height: 100%;
    &__logo__box {
      position: relative;
      width: 100%;
      @include flex_layout();
      margin-bottom: 1vw;
      & > img {
        width: 55%;
        height: auto;
        object-fit: cover;
      }
    }
    & > nav {
      position: relative;
      width: 100%;
      & > ul {
        & > li {
          width: 100%;
          list-style: none;
          cursor: pointer;
          padding-left: 1rem;
          &:hover {
            background-color: #99219b;
            .list_text {
              color: white;
            }
          }
          .link {
            @include flex_layout(center, null);
            text-decoration: none;
            padding: 0.5rem;
            display: flex;
            align-items: center;
          }
          img {
            width: 2vw;
            height: 2vw;
            object-fit: cover;
            margin-right: 1rem;
          }
          .list_text {
            color: rgb(141, 133, 133);
            font-size: 1vw;
          }
        }
      }
    }
  }
}
