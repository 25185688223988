@import "../../sass/variable";
@import "../../sass/utilities";

.main__container {
  // display: relative;
  // height: 100vh;
  // overflow-y: hidden;
  margin-left: 0;
  background-color: rgb(236, 234, 234);

  & .child-div {
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  @media screen and (max-width: 768px) {
    min-width: 100vw;
  }
  &.active {
    margin-left: $sidenav_width;
  }
}
