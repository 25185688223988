@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*
html {
  font-size: 62.5%;
} */

@media screen and (max-width: 768px) {
  html {
    font-size: 55%;
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: 50%;
  }
}
@media screen and (max-width: 450px) {
  html {
    font-size: 47%;
  }
}
@media screen and (max-width: 400px) {
  html {
    font-size: 42%;
  }
}
@media screen and (max-width: 350px) {
  html {
    font-size: 38%;
  }
}
@media screen and (max-width: 300px) {
  html {
    font-size: 35%;
  }
}
body {
  font-family: "Poppins", sans-serif;
}

.App {
  display: relative;
  /* overflow: hidden; */
  width: 100%;
  background-color: "#f1f2f2";
}
