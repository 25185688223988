@import "../../sass/variable";
@import "../../sass/utilities";

.header {
  position: sticky;
  top: 0;
  width: 82vw;
  z-index: 1;
  // height: max-content;
  background-color: white;
  &__toggle {
    padding: 0.8vw 0.5vw;
    display: flex;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      & > div {
        width: max-content;
        display: flex;
        align-items: center;
        margin: 0 1.6vw;
        & > img {
          display: inline-block;
          width: 2.2vw;
          height: 2.2vw;
          user-select: none;
        }
        & > p {
          margin-left: 0.7vw;
          color: #414141;
          font-size: 1vw;
        }
      }
    }
  }
}
